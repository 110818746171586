<template>
    <main class="main">

        <nav aria-label="breadcrumb" class="breadcrumb-nav mb-2">
            <div class="container">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link to="/">{{ $t('home') }}</router-link>
                    </li>
                    <li class="breadcrumb-item">
                        <router-link to="/merken/">{{ $t('brands') }}</router-link>
                    </li>
                    <li class="breadcrumb-item active">{{ pageTitle }}</li>
                    <li class="breadcrumb-item" v-if="$route.query.searchTerm">
                        <span>Search - {{ $route.query.searchTerm }}</span>
                    </li>
                </ol>
                <h4>Alcatel</h4>
            </div>
        </nav>

        <div class="page-content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-9 skeleton-body skel-shop-products" :class="{ loaded: loaded }">
                        <div class="row flex-column-reverse flex-md-row ">
                            <div class="col-md-9 category-text-seo">
                                <h5> Alle autohouders SEP tekst</h5>
                                <p> Bij Mobile Supplies vind je alle mogelijke accessoires voor je smartphone. Ons
                                    uitgebreide
                                    aanbod
                                    bestaat uit meer dan 10.000 verschillende producten. Of je nu een smartphone hoesje,
                                    een
                                    oplader
                                    of
                                    een kabel zoekt, bij ons vind je het allemaal voor de beste prijs en bestel je
                                    eenvoudig
                                    online.
                                </p>
                                <read-more :text="seoMsg" :max-chars="81"></read-more>
                            </div>
                            <div class="col-md-3 d-flex justify-content-end">
                                <img src="@/esf_weert_mobilesupplies/assets/static/images/products/iphone_14.png"
                                    alt="brand" class="img-fluid">
                            </div>
                        </div>

                        <pagination-comp :per-page="perPage" :total="totalCount" :layout-type="layout" :sortBy="sortBy"
                            @handleLayout="handleLayout" @handlePerPage="handlePerPage" @handleSortBy="handleSortBy" />

                        <!-- <p class="no-results" v-if="shop.length === 0 && loaded">No products matching your selection.
                        </p> -->

                        <div class="cat-blocks-container">
                            <template v-if="type !== 'list'">
                                <div class="row">
                                    <div class="col-6 col-md-4 col-lg-3" v-for="product in paginatedData"
                                        :key="product.url_key">
                                        <!-- <router-link :to="'/productDetails/' + product.url_key" class="w-100"> -->
                                        <product-home :product="product"></product-home>
                                        <!-- </router-link> -->
                                    </div>
                                    <!-- <div class="col-6 col-md-4 col-lg-3">
                                        <product-home :product="product"></product-home>
                                    </div>
                                    <div class="col-6 col-md-4 col-lg-3">
                                        <product-home :product="product"></product-home>
                                    </div>
                                    <div class="col-6 col-md-4 col-lg-3">
                                        <product-home :product="product"></product-home>
                                    </div> -->
                                </div>
                            </template>

                            <template v-if="type === 'list'">
                                <div class="row" v-for="product in paginatedData" :key="product.url_key">
                                    <div class="col-12">
                                        <!-- <router-link :to="'/productDetails/' + product.url_key" class="w-100"> -->
                                        <product-home-list :product="product"></product-home-list>
                                        <!-- </router-link> -->
                                    </div>
                                </div>
                            </template>
                        </div>

                        <pagination-comp :per-page="perPage" :total="totalCount" :layout-type="layout" :sortBy="sortBy"
                            @handleLayout="handleLayout" @handlePerPage="handlePerPage" @handleSortBy="handleSortBy" />

                        <div class="row">
                            <div class="col-md-12 category-text-seo">
                                <h5> Alle smartphone accessoires</h5>
                                <p> Bij Mobile Supplies vind je alle mogelijke accessoires voor je smartphone. Ons
                                    uitgebreide
                                    aanbod
                                    bestaat uit meer dan 10.000 verschillende producten. Of je nu een smartphone hoesje,
                                    een
                                    oplader
                                    of
                                    een kabel zoekt, bij ons vind je het allemaal voor de beste prijs en bestel je
                                    eenvoudig
                                    online.
                                </p>
                                <Read-more :text="seoMsg" :maxChars="81"></Read-more>
                            </div>
                        </div>
                    </div>

                    <!-- sticky-container -->
                    <aside class="col-lg-3 order-lg-first">
                        <!-- v-sticky="!isSidebar" sticky-offset="{ top: 69 }" -->
                        <div>
                            <button class="sidebar-fixed-toggler" @click="toggleSidebar" v-if="isSidebar">
                                <i class="icon-cog"></i>
                            </button>

                            <div class="sidebar-filter-overlay" @click="hideSidebar"></div>
                            <shop-sidebar-one :is-sidebar="isSidebar"></shop-sidebar-one>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    </main>
</template>

<script>

// import { mapGetters } from 'vuex';
// import Sticky from 'vue-sticky-directive';
import ShopSidebarOne from '@/esf_weert_mobilesupplies/core/components/partial/shop/sidebar/ShopSidebarOne';
import PaginationComp from '@/esf_weert_mobilesupplies/core/components/elements/PaginationComp';
import ReadMore from '@/esf_weert_mobilesupplies/core/components/elements/ReadMore.vue';
import { homeData } from '@/esf_weert_mobilesupplies/utilities/data';
import ProductHome from '@/esf_weert_mobilesupplies/core/components/elements/products/ProductHome';
import ProductHomeList from '@/esf_weert_mobilesupplies/core/components/elements/products/ProductHomeList';

// import Repository, { baseUrl } from '@/repositories/repository.js';
// import { scrollToPageContent } from '@/utilities/common';

export default {
    components: {
        ShopSidebarOne,
        PaginationComp,
        ProductHome,
        ProductHomeList,
        ReadMore,
    },
    // directives: {
    //     Sticky
    // },
    data: function () {
        return {
            products: [],
            pageTitle: "",
            isSidebar: true,
            loaded: false,
            seoMsg: "Het is heel simpel en snel online telefoon accessoires zoeken bij Mobile Supplies. Je vindt gemakkelijk in meer dan 10.000 producten jouw gewenste mobiele telefoon accessoires. Ook als je smartphone stuk is kun je hier bij reparatie onderdelen de juiste onderdelen vinden. Maar als je een leuk hoesje zoekt of gewoon je autolader weer eens kwijt bent vind je bij Mobile Supplies zeker een goede!",
            // perPage: 5,
            // defaultType: 'cols',
            // type: 'list',
            // totalCount: 10,
            // orderBy: 'default',

            // pagination
            perPage: 24,
            layout: 'cols',
            totalCount: 0,
            sortBy: 'price_low_high',
            // pagination
        };
    },
    computed: {
        // pagination
        type: function () {
            return this.layout;
        },
        currentPage: function () {
            return parseInt(this.$route.query.page ? this.$route.query.page : 1);
        },
        paginatedData() {
            if (!this.products) return [];

            let start = (this.currentPage - 1) * this.perPage;
            let end = start + this.perPage;

            // Apply sorting based on sortBy
            let sortedCategories = [...this.products];
            if (this.sortBy === 'price_low_high') {
                sortedCategories.sort((a, b) => a.price - b.price);
            } else if (this.sortBy === 'price_high_low') {
                sortedCategories.sort((a, b) => b.price - a.price);
            }
            // Add more sorting logic if needed

            return sortedCategories.slice(start, end);
        },
        // pagination

        // gridClass: function () {
        //     if (this.type === 'list') return 'col-12';
        //     if (this.type === '3cols') return 'col-6 col-md-4 col-lg-4';
        //     if (this.type === 'cols')
        //         return 'col-6 col-md-4 col-lg-4 col-xl-3';
        //     else
        //         return 'col-6 col-md-4 col-lg-4 col-xl-3';
        // },
        // fakeArray: function () {
        //     let temp = [];
        //     for (let i = 0; i < this.perPage; i++) {
        //         temp.push(i);
        //     }
        //     return temp;
        // },

        // currentbrand() {
        //     return this.$store.getters["product/getBrandSliderByCode"](
        //         this.$route.params.code
        //     );
        // },
    },
    watch: {
        $route: function () {
            this.getProducts(true);
        }
    },
    created: function () {
        this.getProducts();
    },
    mounted: function () {
        this.resizeHandler();
        window.addEventListener('resize', this.resizeHandler, {
            passive: true
        });
    },
    unmounted: function () {
        window.removeEventListener('resize', this.resizeHandler);
    },
    methods: {
        getProducts: async function (samePage = false) {
            console.log(samePage);
            this.type = this.layout;
            this.products = await homeData.products;
            if (this.type == 'list') {
                this.pageTitle = 'Brand Details';
                // this.perPage = 2;
            } else if (this.type == 'cols') {
                this.pageTitle = 'Brand Details';
                // this.perPage = 5;
            }
            this.loaded = true;
            // await Repository.get(`${baseUrl}/shop`, {
            //     params: {
            //         ...this.$route.query,
            //         orderBy: this.orderBy,
            //         perPage: this.perPage,
            //         demo: this.currentDemo
            //     }
            // })
            //     .then(response => {
            //         this.shop = response.data.products;
            //         this.totalCount = response.data.totalCount;
            //         this.loaded = true;

            //         if (samePage) {
            //             scrollToPageContent();
            //         }
            //     })
            //     .catch(error => ({ error: JSON.stringify(error) }));
        },
        toggleSidebar: function () {
            if (
                document
                    .querySelector('body')
                    .classList.contains('sidebar-filter-active')
            ) {
                document
                    .querySelector('body')
                    .classList.remove('sidebar-filter-active');
            } else {
                document
                    .querySelector('body')
                    .classList.add('sidebar-filter-active');
            }
        },
        hideSidebar: function () {
            document
                .querySelector('body')
                .classList.remove('sidebar-filter-active');
        },
        resizeHandler: function () {
            if (window.innerWidth > 991) this.isSidebar = false;
            else this.isSidebar = true;
        },
        // pagination
        handleLayout: function (layout) {
            this.layout = layout;
        },
        handlePerPage: function (count) {
            this.perPage = count;
        },
        handleSortBy: function (sort) {
            this.sortBy = sort;
        },
        // pagination
    }
};
</script>